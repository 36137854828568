import React, { useReducer, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import Card from "../../components/card/Card";
import "bootstrap/dist/css/bootstrap.css";
import Pagination from "../../components/Pagination/Pagination";
import db from "../../utils/db.json";
import "./Body.css";

const initialBooks = {
  loading: true,
  books: [],
  error: "",
  pageNo: 1,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_SUCCESS":
      return {
        loading: action.loading,
        books: action.payload,
        error: "",
        pageNo: action.pageNo,
      };
    case "FETCH_ERROR":
      return {
        loading: false,
        books: [],
        error: "Something Went Wrong!!!",
        pageNo: 1,
      };
    default:
      return state;
  }
};

// Maximum of books to display on each page
const BOOKS_PER_PAGE = 9;

function Body({ match }) {
  const [state, dispatchState] = useReducer(reducer, initialBooks);

  useEffect(() => {
    const data = db.books;
    dispatchState({
      type: "FETCH_SUCCESS",
      payload: data,
      error: "",
      loading: false,
      pageNo: parseInt(match.params.pageNo),
    });
  }, [match]);

  return (
    <div className="body">
      <Container>
        {state.error !== "" ? (
          <h1 className="text-center">{state.error}</h1>
        ) : (
          ""
        )}
        {state.loading ? <h1 className="text-center">Loading</h1> : ""}
        <Row>
          {state.books
            .slice(
              ((match.params.pageNo || 1) - 1) * BOOKS_PER_PAGE,
              (match.params.pageNo || 1) * BOOKS_PER_PAGE
            )
            .map((book) => {
              return (
                <Col key={book.id} md={4} sm={6} xs={12}>
                  <Card
                    // This is technically a React code smell (spread props)
                    // But I didn't want to wire up global state management just for book info
                    // So Body is essentially just a passthrough, and Card will get all properties of books from the DB
                    {...book}
                    id={book.id}
                  />
                </Col>
              );
            })}
        </Row>
        <Pagination
          page="/"
          pageNo={state.pageNo}
          itemCount={state.books.length}
          isNextBtnEnabled={
            db.books.length > (match.params.pageNo || 1) * BOOKS_PER_PAGE
          }
        />
      </Container>
    </div>
  );
}
export default Body;
