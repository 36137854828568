import React, {memo} from 'react'
import { HIDE_SUBSCRIPTION_COOKIE_KEY } from '../signup-modal/signup-modal';
import './Footer.css'

const readyToSubscribe = () => {
  // Delete all cookies
  document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
}

function Footer() {
  return (
    <div className="card-footer text-muted text-center font-weight-bolder p-5 light-bg">
      <div className="container">
        <div className="row">
          <div className="col-sm">
            <a href="https://www.goodreads.com/user/show/161190325-quinn-thomas" target="_blank" className="text-secondary">Goodreads</a><br/>
            <a href="https://www.amazon.com/stores/Quinn-Thomas/author/B0BS493VHK?ref=ap_rdr&store_ref=ap_rdr&isDramIntegrated=true&shoppingPortalEnabled=true" target="_blank" className="text-secondary">Amazon</a>
          </div>
          <div className="col-sm">
          </div>
          <div className="col-sm">
            <a href="" className="text-secondary" onClick={() => readyToSubscribe()}>Email updates</a>
          </div>
        </div>
      </div>
    </div>
  )
}
export default memo(Footer)
