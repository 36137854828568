import React, {memo} from 'react'
import {Link} from 'react-router-dom'
import "./Card.css";

function Card(props) {
  return (
    <div
      className="card specialCard mx-auto"
      style={{
        width: '18rem',
        padding: '20px',
        backgroundColor: 'rgb(246,246,246)',
        border: 'none',
        margin: '15px',
      }}
    >
      <a href={props.orderLink}
      target="_blank">
        <img
        src={props.coverLink}
        className="card-img-top"
        alt={`Cover for ${props.title}`}
        />
      </a>
      <div className="card-body text-center">
        <h5 className="card-title text-black-50">{props.title}</h5>
        <h6 className="card-title text-black-50">{props.publishDate}</h6>
        <h6 className="card-title text-black-50">{props.pitch}</h6>

        <a
          className="btn btn-outline-primary" target="_blank"
          href={props.orderLink}
        >
          Buy on Amazon
        </a>
      </div>
    </div>
  )
}
export default memo(Card)
