import React, {useState, useEffect, useCallback, useMemo} from 'react'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'
import './NavigationBar.css'
import {Link} from 'react-router-dom'

function NavigationBar() {
  const [selectedLink, setselectedLink] = useState(null);
  const toggle = (selected) => setselectedLink(selected);

  let pathName = useMemo(
    () => selectedLink ? selectedLink : window.location.pathname.split('/')[1],
    [window.location.pathname, selectedLink],
  )

  return (
    <div className="navBar1">
      <Navbar
        className="d-flex justify-content-between"
        expand="md"
      >
        <Link to="/" className="text-white text-decoration-none" onClick={() => toggle('')}>
          Quinn Thomas
        </Link>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <Link className="text-decoration-none" to="/" onClick={() => toggle('')}>
                <p
                  className={`m-2 ${
                    !pathName || pathName === '' ? 'text-white' : 'text-secondary '
                  }`}
                >
                  {' '}
                  Books
                </p>
              </Link>
            </NavItem>
            <NavItem>
              <Link className="text-decoration-none" to="/about" onClick={() => toggle('about')}>
                <p
                  className={`m-2 ${
                    pathName === 'about' ? 'text-white' : 'text-secondary '
                  }`}
                >
                  {' '}
                  About
                </p>
              </Link>
            </NavItem>
            <NavItem>
              <Link className="text-decoration-none" to="/recommended" onClick={() => toggle('recommended')}>
                <p
                  className={`m-2 ${
                    pathName === 'recommended' ? 'text-white' : 'text-secondary '
                  }`}
                >
                  {' '}
                  Recommendations
                </p>
              </Link>
            </NavItem>
            {/* <NavItem>
              <Link className="text-decoration-none" to="/MostLikedPost" onClick={() => toggle('MostLikedPost')}>
                <p
                  className={`m-2 ${
                    pathName === 'MostLikedPost'
                      ? 'text-white'
                      : 'text-secondary'
                  }`}
                >
                  {' '}
                  MostLikedPost
                </p>
              </Link>
            </NavItem> */}
          </Nav>
      </Navbar>
    </div>
  )
}

export default NavigationBar
