import React from "react";
import { Container } from "reactstrap";
import "bootstrap/dist/css/bootstrap.css";
import "./SignupConfirmPage.css";

function SignupConfirmPage() {
  return (
    <div className="body">
      <Container>
        <div className="row mt-3 about-text">
          <h3>
            You've successfully signed up for Quinn Thomas' ARC list! Your copy
            of Death's Reckoning should be on the way soon. If you don't see it,
            check your inbox and/or reach out to me on Goodreads and I'll be
            happy to send a copy your way.
          </h3>
        </div>
      </Container>
    </div>
  );
}
export default SignupConfirmPage;
