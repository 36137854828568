import React from 'react'
import {Container} from 'reactstrap'
import 'bootstrap/dist/css/bootstrap.css'
import './RecommendationsPage.css'

function RecommendationsPage() {
  return (
    <div className="body mb-3">
      <Container>
        <div className="row mt-3 about-text">
          <div className="col-xs">
            <img src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1461354651l/15839976.jpg" className='cover'/>
          </div>
          <div className="col-lg text-light">
          Pierce Brown's <i>Red Rising</i> series is superlative. A cross between <i>Brave New World</i> and <i>Hunger Games</i>.
           Brown is a master of small character moments, and as a consequence does a fantastic job of making you care about all of the characters (even those who aren't viewpoint characters). 
           While it has moments of uber-mensch mentality, this series is full of believable betrayals and twists that kept me coming back. Highly recommend!
          </div>
        </div>
        <div className="row mt-3 about-text">
          <div className="col-xs">
            <img src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1603885729l/50523477._SY475_.jpg" className='cover'/>
          </div>
          <div className="col-lg text-light">
          Tasha Suri's <i>Jasmine Throne</i> series is another great one. I guarantee you that you haven't seen a magic system quite like this.
          Two strong female characters lead the cast, one a princess who refused to allow herself to be immolated for her sadistic brother; the other
          a mystic who's one of only three survivors of a massacre targeting her people. What starts off as a story of their survival quickly turns into 
          a war against the tyranny of Princess Malini's brother. But what was most interesting to me about this story is the emphasis on what characters had to 
          give up to obtain power, particularly Priya.
          </div>
        </div>
        <div className="row mt-3 about-text">
          <div className="col-xs">
            <img src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1617768316l/68428._SY475_.jpg" className='cover'/>
          </div>
          <div className="col-lg text-light">
          I doubt I'm the first person you've heard recommend Sanderson's <i>Mistborn</i> series. It's been around for a while, and is deservedly popular.
          If you haven't heard of it before, the best way I've heard it pitched is this: a world in which the prophesied champion failed. Sanderson
          himself pitches it as Ocean's Eleven meets My Fair Lady.
          <br/>
          I loved this series for several reasons. First, Sanderson's depiction of Vin is <i>*chef's kiss*</i> perfection for a male author writing a female character.
          He never became obsessed with her looks or body, which is one of my greatest pet peeves for most male fantasy authors. She had rebellious moments, but was
          competent to the end. Her affection for Elend -- and his for her -- felt very natural, all the way to the end of the series.
          <br/>
          And of course, a trio of delightfully twisty plots. Sanderson's ability to pull off a late-novel twist is unparalleled. If you haven't already given this
          a look, do. You won't regret it. 
          </div>
        </div>
      </Container>
    </div>
  )
}
export default RecommendationsPage
