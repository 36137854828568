import React, { memo, useState } from "react";
import Modal from "react-modal";
import "./signup-modal.css";
import fetch from "isomorphic-fetch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    maxWidth: "80vw",
    transform: "translate(-50%, -50%)",
  },
};

export const HIDE_SUBSCRIPTION_COOKIE_KEY = "hideSubscriptionBox";

function SignupModal() {
  const isSubscribeCookieSet = () => {
    return document.cookie.includes(HIDE_SUBSCRIPTION_COOKIE_KEY);
  };

  const [modalIsOpen, setIsOpen] = React.useState(true);
  const [isDisplayed, setIsDisplayed] = useState(!isSubscribeCookieSet());

  const postRequest = (url, data) => {
    return fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Origin: "https://example.reqbin.com",
        "Access-Control-Request-Method": "POST",
        accept: "application/json",
        "Content-Type": "application/json",
        "Authorization":
          "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiYzQ1ZjFmZTg1OTRhMjk0OWQ2MjBiYzNhNjlhMWQxMDkyYzRlZTA2NTc2M2JlMWU5MjQ5ODFiMGM4MjE5ZTRkNDdkZmU5ODVkZWI0YTc5MDUiLCJpYXQiOjE2ODEzNDIyNjEuODg2NTEzLCJuYmYiOjE2ODEzNDIyNjEuODg2NTE1LCJleHAiOjQ4MzcwMTU4NjEuODgyMjY3LCJzdWIiOiI0MjkyNTkiLCJzY29wZXMiOltdfQ.sv5UGg9M9w2GesJ9vYrpa9RvsjeEdjK0opiUuU1oa-Vqzx6IZtho2aZwWSp1kgrrZtrmDpt87M6trRG5omEqogSOX23qx59NQWXN4tvC2jbDm3gDjCn15QlCRsEx6i4qOj30WQ0Ld9x4ORt1M1WUSbk2JNOIMUl8dhsJrqnPKK5YguPjlIkqvZ7cUednDpmxi-uGM1rWWNlnHjD5vbgUvBRe9IOOLXNAeDoCYZKSZCrquONoKK3lr1XIAAjTRIoELktZ-M64ZrXrrKiuIRPmE3aOXegYCRIbQnzsEtRiw8uC4IEtKvsE2yJIifDFMNi0WbifeMqoGP_oJzILeVJyP2778J0Z-wu4Pj3NgtWGcUtYmgLG_1mOk_TMl61fMbf38wsU-MV3AebnbBNcpxcmyxJ_dptqrd7gXaCtYobvWwGEXu20Z9j38EHkt-jENvXZG_IAkoKJOIg1nnoYAb2q-ieFFYjwRcfe3qKjkngJ2Sb_2Jz35kpbYwiWqte9iNgE5oQJNNBLFzKHLcXIpnvCxmV0dmKFs2EHm9YXE7drBKgaXf2DCi9Du5BNt32mUXYew5BTByk2ykShaZFzQEAXa5lKi4fcgsAFvLgS9PcIIAZh5Uvcr9PvgnI4Rz6u3JzwfYKskZPljEmliCkzG4w7Qe_m3Z832WdtgZ-jaiHvlTM",
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          alert("Successfully subscribed to author updates for Quinn Thomas!");
          setIsOpen(false);
          return response;
        } else {
          alert("Request failed. Please refresh and try again.");
        }
      })
      .catch((err) => err);
  };

  const submitNewReader = (event) => {
    event.preventDefault();
    if (!event.target[0] || !event.target[0].value) {
      alert("Email is required");
      return;
    }
    const email = event.target[0].value;

    postRequest("https://connect.mailerlite.com/api/subscribers", {
      email: email,
      groups: [
        "86101356035703898"
      ]
    });
    writeSubscribeCookie();
  };

  const writeSubscribeCookie = (ttlInSeconds) => {
    const cookieNameEntry = `${HIDE_SUBSCRIPTION_COOKIE_KEY}=true`;
    const ttlEntry = `max-age=${ttlInSeconds}`;
    document.cookie = `${cookieNameEntry};${ttlEntry}`;
  };

  // Called when user clicks 'x'
  const toggleDisplayed = () => {
    setIsDisplayed(!isDisplayed);
    // TTL should be one month (60 * 60 * 24 * 30)
    // Idea is that if they manually cancelled out, they may forget that this is an option
    writeSubscribeCookie(2592000);
  };

  function closeModal() {
    setIsOpen(false);
    toggleDisplayed();
  }

  if (!isDisplayed) {
    return <></>;
  }

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <form onSubmit={(event) => submitNewReader(event)}>
          <div className="float-right mr-2 w-100">
            <h5 className="float-left ml-2">Like free books?</h5>{" "}
            <span onClick={() => toggleDisplayed()}>
              <FontAwesomeIcon
                icon={faXmark}
                className="float-right close-btn"
              />
            </span>
          </div>
          <div class="form-group ">
            <label>
              Would you like a free copy of Death's Reckoning, my debut epic
              fantasy novel? Subscribe to my newsletter and I'll send you an ARC
              (Advanced Review Copy) as a thank you gift! You'll also receive
              updates when I publish new novels and be on the short-list for
              freebies and giveaways.
            </label>
          </div>
          <div class="form-group ">
            <label for="EMAIL">Email address</label>
            <input
              type="email"
              required
              class="form-control"
              id="EMAIL"
              name="EMAIL"
              aria-describedby="emailHelp"
              placeholder="Enter email"
            />
          </div>
          <button className="btn btn-primary mt-2 mb-1" type="submit">
            Subscribe
          </button>
        </form>
      </Modal>
    </div>
  );
}
export default memo(SignupModal);
