import React from "react";
import "./App.css";
// import { Button } from "reactstrap";

import "bootstrap/dist/css/bootstrap.css";

import Body from "./Pages/HomePage/Body";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import MostLikedPost from "./Pages/MostLikedPost/MostLikedPost";
import NavigationBar from "./components/NavBar/NavigationBar";
import Footer from "./components/Footer/Footer";
import SignupModal from "./components/signup-modal/signup-modal";
import AboutPage from "./Pages/AboutPage/AboutPage";
import RecommendationsPage from "./Pages/Recommendations/RecommendationsPage";
import SignupConfirmPage from "./Pages/SignupConfirmPage/SignupConfirmPage";

function App() {
  return (
    <div className="App">
      <Router>
        <NavigationBar />
        <div className="row">
          <div className="col-md">
            <Switch>
              <Route exact path="/" component={Body} />
              <Route exact path="/about" component={AboutPage} />
              <Route
                exact
                path="/signUpConfirmation"
                component={SignupConfirmPage}
              />
              <Route
                exact
                path="/recommended"
                component={RecommendationsPage}
              />
              <Route exact path="/MostLikedPost" component={MostLikedPost} />
              <Route exact path="/:pageNo" component={Body} />
            </Switch>
          </div>
          <div className="col-xs">
            <SignupModal />
          </div>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
