import './Pagination.css'

import React, {memo} from 'react'
import {Link} from 'react-router-dom'
function Pagination(props) {
  if (props.itemCount > 9)
  return (
    <div className="text-center w-100">
      <div className="btn-group" role="group" aria-label="Basic example">
        <Link to={`/${props.pageNo - 1}`} className={props.pageNo > 1 ? "btn btn-outline-primary": "btn btn-outline-primary disabled"}>Left</Link>
        <button type="button" className="btn disabled btn-outline-secondary shadow-none no-outline">
          {isNaN(props.pageNo) || props.pageNo === 0 ? '1' : props.pageNo}
        </button>
        <Link to={`/${isNaN(props.pageNo) || props.pageNo === 0 ? '2' : props.pageNo + 1}`} 
          className={props.isNextBtnEnabled ? "btn btn-outline-primary": "btn btn-outline-primary disabled"}>Right</Link>
      </div>
    </div>
  )
}

export default memo(Pagination)
