import React from "react";
import { Container } from "reactstrap";
import "bootstrap/dist/css/bootstrap.css";
import "./AboutPage.css";

function AboutPage() {
  return (
    <div className="body">
      <Container>
        <div className="row mt-3 about-text">
          <div className="col-xs">
            <img src="./Author Portrait.jpg" className="portrait" />
          </div>
          <div className="col-lg text-light">
            Quinn Thomas is an Epic Fantasy author and occasional pool shark
            from Denver, Colorado. When she’s not writing stories about people
            thrust into situations that turn them into heroes (or more
            frequently, villains), she’s wrestling her 100-lb labrador
            retriever. Her favorite fantasy series is Mistborn by Brandon
            Sanderson.
            <br />
            <br />
            Things Quinn has tried exactly once and wants to mention because she
            thinks they make her seem cool: skydiving, surfing, paddle-boarding,
            yoga.
            <br />
            <br />
            Things Quinn has tried several times and wants to mention because
            they make her seem less cool: eating Cheetos on the couch while
            wearing yoga pants, tripping on her own feet, singing karaoke songs
            completely sober.
          </div>
        </div>
      </Container>
    </div>
  );
}
export default AboutPage;
